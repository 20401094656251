<template>
    <div>
        <b-carousel controls id="celebration"  :indicators="chunks.length >= 2 ? true : false"
            :touch="chunks.length >= 2 ? true : false">
            <b-carousel-slide v-for="(chunk, index) in chunks" :key="index">
                <ul class="m-0 p-0 celeb-details">
                    <li
                        v-for="celebration in chunk"
                        :key="celebration.id"
                        class="cursor-pointer list-unstyled d-flex align-items-center my-1 justify-content-between "
                        
                    >
                        <div class="d-flex align-items-center userinfo">
                            <span
                                class="b-avatar mr-75 badge-secondary rounded-circle"
                                style="
                                    width: 40px;
                                    height: 40px;
                                    background: transparent;
                                "
                            >
                            <a :href="`/profile/${celebration.user.hashid}`" target="_blank">
                                <span class="b-avatar-img">
                                    <img
                                        :src="
                                            celebration.user.avatar ||
                                            'assets/images/dummy-avatar.png'
                                        "
                                        alt="avatar"
                                    />
                                </span>
                                </a>
                            </span>
                            <div class="profile-user-info"
                             v-b-toggle.add-sidebar-view
                            @click="$emit('view-post',celebration.post)">
                                <h6 class="mb-0"    
                                >
                                    {{ celebration.user.name }}
                                </h6>

                                <small
                                    v-if="
                                        celebration.celebration_type ===
                                        'Birthday'
                                    "
                                >
                                    {{
                                       'Wish them a Happy Birthday'
                                    }}
                                </small>

                                <small v-else>
                                    {{
                                     'Wish them a Happy Anniversary!'
                                    }}
                                </small>
                            </div>
                        </div>
                        <div
                            class="expand-cele"
                            v-if="userData.hashid !== celebration.user.hashid"
                            v-b-modal.wishes-popup
                            @click="selectCelebration(celebration)"
                        >
                            <feather-icon
                                icon="GiftIcon"
                                size="18"
                                stroke="#667085"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </div>
                        <div
                            class="expand-cele"
                            v-else
                        >
                            <feather-icon
                                icon="GiftIcon"
                                size="18"
                                stroke="#667085"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </div>
                    </li>
                </ul>
            </b-carousel-slide>
        </b-carousel>
        <!-- End Celebration Wishes Popup -->
        <b-modal id="wishes-popup" hide-footer centered>
            <template #modal-header="{ close }">
                <feather-icon
                    class="cursor-pointer floating-close"
                    icon="XIcon"
                    size="20"
                    @click="hideModal()"
                />
            </template>
            <div class="cele-flag">
                <b-img :src="require('@/assets/images/icons/left-flag.svg')" class="flag-left"/>
                <b-img :src="require('@/assets/images/icons/right-flag.svg')" class="flag-right"/>
            </div>
            <div
                class="wishes-body d-flex align-items-center justify-content-center flex-column"
                v-if="selectedCelebrations"
            >
                <!-- User image -->
                <div class="employee-img">
                    <b-img
                        :src="
                            selectedCelebrations.user.avatar ||
                            require('@/assets/images/avatars/1.png')
                        "
                    />
                </div>

                <div class="name-typewish text-center">
                    <h2 class="mb-0">
                        <span>{{ selectedCelebrations.user.name }}</span>
                    </h2>
                    <h4
                        class="mb-2"
                        v-if="
                            selectedCelebrations.celebration_type === 'Birthday'
                        "
                    >
                        {{
                            'Is celebrating their birthday today! Join us in wishing them.'
                        }}
                    </h4>
                    <!-- Anniversary wishes -->
                    <h4 class="mb-2" v-else>
                        {{
                            selectedCelebrations.user_settings &&
                            selectedCelebrations.user_settings.settings
                                ? getWorkAnniversary(
                                      selectedCelebrations.user_settings
                                          .settings.date_of_join
                                  )
                                : "Is celebrating their Work Anniversary today! Join us in wishing them"
                        }}
                    </h4>
                </div>
                <div class="choice-illustrate">
                    <ul
                        class="list-unstyled m-0 p-0 d-flex align-items-center justify-content-center"
                    >
                        <li
                            v-for="(icon, index) in wish.slice(0, 4)"
                            :key="index"
                            :class="{ selected: selectedWish === icon.name }"
                            @click="selectWish(icon.name)"
                        >
                            <div class="icon-select">
                                <b-img :src="icon.src" />
                            </div>
                            <feather-icon
                                size="16"
                                icon="CheckIcon"
                                class="select-icon"
                                v-if="selectedWish === icon.name"
                                stroke-width="3"
                            />
                        </li>
                    </ul>

                    <ul
                        class="list-unstyled m-0 p-0 d-flex align-items-center justify-content-center"
                    >
                        <li
                            v-for="(icon, index) in wish.slice(4, 8)"
                            :key="index"
                            :class="{ selected: selectedWish === icon.name }"
                            @click="selectWish(icon.name)"
                        >
                            <div class="icon-select">
                                <b-img :src="icon.src" />
                            </div>
                            <feather-icon
                                size="16"
                                icon="CheckIcon"
                                class="select-icon"
                                v-if="selectedWish === icon.name"
                                stroke-width="3"
                            />
                        </li>
                    </ul>
                </div>

                <div class="send-wishes mt-1">
                    <b-form-textarea
                        id="textarea"
                        placeholder="Type your wishes"
                        class=""
                        v-model="wishText"
                    ></b-form-textarea>
                    <div class="d-flex align-items-center justify-content-end">
                        <b-button
                            variant="primary"
                            class="send-btn d-flex align-items-center justify-content-center my-1"
                            pill
                            @click="addWish(selectedCelebrations)"
                        >
                            <span>Send</span>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="19"
                                height="18"
                                viewBox="0 0 19 18"
                                fill="none"
                            >
                                <g clip-path="url(#clip0_13236_45328)">
                                    <path
                                        d="M5.37927 9.51136H16.652"
                                        stroke="white"
                                        stroke-width="1.08696"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                    <path
                                        d="M16.6532 9.51255L4.09946 15.4051C4.02792 15.4317 3.95026 15.4372 3.8757 15.4209C3.80114 15.4046 3.73281 15.3673 3.67885 15.3133C3.62489 15.2594 3.58755 15.191 3.57128 15.1165C3.55501 15.0419 3.56049 14.9642 3.58707 14.8927L5.38045 9.51255L3.58707 4.13238C3.56049 4.06085 3.55501 3.98318 3.57128 3.90862C3.58755 3.83406 3.62489 3.76574 3.67885 3.71177C3.73281 3.65781 3.80114 3.62047 3.8757 3.6042C3.95026 3.58793 4.02792 3.59341 4.09946 3.61999L16.6532 9.51255Z"
                                        stroke="white"
                                        stroke-width="1.08696"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                </g>
                                <defs>
                                    <clipPath id="clip0_13236_45328">
                                        <rect
                                            width="17.3913"
                                            height="17.3913"
                                            fill="white"
                                            transform="translate(0.875 0.125)"
                                        />
                                    </clipPath>
                                </defs>
                            </svg>
                        </b-button>
                    </div>
                </div>
            </div>
        </b-modal>
        <!-- End Celebration Wishes Popup -->

        <!-- New Code -->

        <!-- Loader -->
        <b-col cols="12" order="4" v-if="busy">
            <div
                class="text-center mb-2 d-flex align-items-center justify-content-center"
            >
                <div :show="busy" class="d-flex align-items-center">
                    <b-spinner
                        variant="primary"
                        class="m-1"
                        label="Spinning"
                    ></b-spinner>
                </div>
            </div>
        </b-col>
    </div>
</template>

<script>
import {
    BCarousel,
    BCarouselSlide,
    BAvatar,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    VBTooltip,
    BFormTextarea,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
import { onMounted, ref, watch } from "@vue/composition-api";
import vSelect from "vue-select";
import store from "@/store";
import { onUnmounted } from "@vue/composition-api";
import Vue from "vue";
import VueCookies from "vue-cookies";
Vue.use(VueCookies);
import { bus } from "@/main.js";
import infiniteScroll from "vue-infinite-scroll";
Vue.use(infiniteScroll);
import $ from "jquery";

export default {
    components: {
        ToastificationContent,
        BSidebar,
        BForm,
        BFormGroup,
        BFormInput,
        BFormInvalidFeedback,
        BButton,
        BFormTextarea,
        BCarousel,
        BCarouselSlide,
        BAvatar,
    },
    props: {
        recentCelebrations: {
            type: Array,
            required: true,
        },
    },
    directives: {
        Ripple,
        "b-tooltip": VBTooltip,
        infiniteScroll,
    },

    data() {
        return {
            isBusy: true,
            userData: this.$cookies.get("userData"),
            sidebarEventViewActive: false,
            eventId: "",
            optionsSelect: ["Yes", "No", "Maybe"],
            busy: false,
            lastPage: 1,
            nextPage: 1,
            currentPage: 1,
            type: "",
            postId: null,
            postData: null,
            isPopupVisible: false,
            selectedCelebrations: null,
            wishText: "",
            wish: [
                {
                    name: "cheer",
                    src: require("@/assets/images/icons/wishes/cheer.svg"),
                },
                {
                    name: "gift",
                    src: require("@/assets/images/icons/wishes/gift.svg"),
                },
                {
                    name: "hooray",
                    src: require("@/assets/images/icons/wishes/hooray.svg"),
                },
                {
                    name: "party",
                    src: require("@/assets/images/icons/wishes/party.svg"),
                },
                {
                    name: "bigparty",
                    src: require("@/assets/images/icons/wishes/bigparty.svg"),
                },
                {
                    name: "birthday",
                    src: require("@/assets/images/icons/wishes/birthday.svg"),
                },
                {
                    name: "surprise",
                    src: require("@/assets/images/icons/wishes/surprise.svg"),
                },
                {
                    name: "celeb",
                    src: require("@/assets/images/icons/wishes/celeb.svg"),
                },
            ],
            selectedWish: null,
        };
    },
    computed: {
        chunks() {
            const chunkSize = 3;
            let result = [];
            for (
                let i = 0;
                i < this.recentCelebrations.length;
                i += chunkSize
            ) {
                result.push(this.recentCelebrations.slice(i, i + chunkSize));
            }
            return result;
        },
    },
    setup() {
        const USER_APP_STORE_MODULE_NAME = "app-posts";

        // Register module
        if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
            // store.registerModule(USER_APP_STORE_MODULE_NAME, postStoreModule);

            // UnRegister on leave
            onUnmounted(() => {
                if (store.hasModule(USER_APP_STORE_MODULE_NAME))
                    store.unregisterModule(USER_APP_STORE_MODULE_NAME);
            });

        const { refFormObserver, getValidationState, resetForm } =
            formValidation();
        return {
            refFormObserver,
            getValidationState,
            resetForm,
        };
    },

    mounted() {
        if (this.$route.query.post_hash_id) {
            this.postId = this.$route.query.post_hash_id;
        }
    },

    watch: {
        recentEvents(val) {
            if (val.length >= 0) {
                this.isBusy = false;
            }
        },
        sidebarEventAddActive(val) {
            this.$refs.add_event_sidebar.show();
        },
        searchQuery(val) {
            if (val.length > 3 || val.length == 0) {
                this.getEvents();
            }
        },
    },
    methods: {
        getWorkAnniversary(dateOfJoin) {
            const joinDate = new Date(dateOfJoin);
            const today = new Date();

            const yearsDifference =
                today.getFullYear() - joinDate.getFullYear();

            const isAnniversary =
                today.getMonth() === joinDate.getMonth() &&
                today.getDate() === joinDate.getDate();

            if (yearsDifference > 0 && isAnniversary) {
                return `Is celebrating their ${this.ordinal(yearsDifference)} Work Anniversary today! Join us in wishing them`;
            } else {
                return 'Is celebrating their Work Anniversary today! Join us in wishing them';
            }
        },
        ordinal(n) {
            const s = ["th", "st", "nd", "rd"],
                v = n % 100;
            return n + (s[(v - 20) % 10] || s[v] || s[0]);
        },
        selectCelebration(celebration) {
            this.selectedCelebrations = celebration;
        },

        selectWish(icon) {
            this.selectedWish = icon;
        },

        clearSelectedCelebration() {
            this.selectedCelebration = null;
            this.selectWish("");
            this.wishText = "";
        },
        hideModal() {
            this.$bvModal.hide("wishes-popup");
        },
        addWish(celebration) {
            if (this.wishText.trim() === "") {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: "Can't post empty wish",
                        icon: "BellIcon",
                        variant: "danger",
                    },
                });
                return;
            }
            let payLoad = {
                icon_type: this.selectedWish,
                celebration_id: celebration.hashid,
                message: this.wishText,
            };
            this.$http
                .post(`wish/add`, payLoad)
                .then((res) => {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "Wish send successfully",
                            icon: "BellIcon",
                            variant: "success",
                        },
                    });

                    this.clearSelectedCelebration();
                    this.hideModal();
                })
                .catch((error) => {
                    const errorMessage = error.response?.data?.errors?.message?.[0] || "An error occurred";
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "Wish send failed",
                            icon: "BellIcon",
                            variant: "danger",
                            text: errorMessage,
                        },
                    });
                    this.commentSubmitClicked = false;
                });
        },
        joinUrl(url, hash_id) {
            let new_url = this.$router.resolve({
                path: "/join",
                query: { type: "event", hash_id: hash_id, url: url },
            });
            return window.location.origin + new_url.href;
        },
        visibilityChanged(isVisible, entry, customArgument) {
            if (isVisible) {
                this.isVisible = isVisible;
                this.getEventData(customArgument.hashid);
            }
        },
        getEventData(itemId) {
            if (!itemId) {
                console.log("no itemId");
                return true;
            }

            let data = {};
            data.url = "/events/" + itemId;
            this.isBusy = true;
            this.$store
                .dispatch("app/get", data)
                .then((res) => {
                    this.isBusy = false;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        open_join_url(url, hash_id) {
            let data = {};
            data.url = url;
            console.log(url);
            let routeData = this.$router.resolve({
                path: "/join",
                query: { type: "event", hash_id: hash_id, url: url },
            });
            return window.open(routeData.href, "_blank");
        },
        copy(copyText) {
            // Select the text field
            copyText.select();
            copyText.setSelectionRange(0, 99999); // For mobile devices

            // Copy the text inside the text field
            navigator.clipboard.writeText(copyText.value);
        },
        onCopy() {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: "Text copied",
                    icon: "BellIcon",
                },
            });
        },
        onError() {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: "Failed to copy texts!",
                    icon: "BellIcon",
                },
            });
        },
        editRecord(event) {
            this.eventId = event.hashid;
        },
        getEvents() {
            let data = {};
            data.url = "/events";
            data.params = {
                q: this.searchQuery,
                type: this.type,
                createdBy: this.$route.params.id,
                perPage: 3,
            };
            this.$store
                .dispatch("app/get", data)
                .then((res) => {
                    this.recentEvents = res.data.data;
                    this.busy = false;
                    this.nextPage = res.data.meta.current_page + 1;
                    this.lastPage = res.data.meta.last_page;
                    this.currentPage = res.data.meta.current_page;
                })
                .catch((error) => {
                    console.log(error);
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "Oops! Events list Failed",
                            icon: "BellIcon",
                            variant: "danger",
                        },
                    });
                });
        },

        confirmDeleteRecord(data) {
            this.$swal({
                title: "Are you sure?",
                text: `You want to delete ${data.name}.`,
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Yes, delete it!",
                customClass: {
                    confirmButton: "btn btn-primary rounded-pill",
                    cancelButton: "btn btn-outline-danger rounded-pill ml-1",
                },
                buttonsStyling: false,
            }).then((result) => {
                if (result.value) {
                    this.deleteRecord(data.hashid);
                }
            });
        },
        deleteRecord(id) {
            let data = {};
            data.url = "events/" + id;
            this.$store
                .dispatch("app/delete", data)
                .then((res) => {
                    if (res.status == "204") {
                        document.getElementById("event-card-" + id).remove();
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "Event Deleted",
                                icon: "BellIcon",
                                variant: "success",
                                text: "Event Deleted Successfully.",
                            },
                        });
                    } else {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "Event Deletion failed.",
                                icon: "BellIcon",
                                variant: "danger",
                                text: res.data.message,
                            },
                        });
                    }
                    // this.refetchData();
                })
                .catch((err) => {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "Event Deletion failed.1",
                            icon: "BellIcon",
                            variant: "danger",
                        },
                    });
                    console.error(err);
                });
        },

        openWindow(link) {
            window.open(link);
        },

        updateIsGoing(event) {
            if (!event.hashid) {
                console.log("no itemId");
                return true;
            }

            let data = {};
            data.url = "/events/" + event.hashid + "/update-status";
            data.params = {
                isGoing: event.isGoing,
            };
            this.isBusy = true;
            this.$store
                .dispatch("app/update", data)
                .then((res) => {
                    this.isBusy = false;
                })
                .catch((error) => {
                    this.isBusy = false;
                    console.log(error);
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "Oops! Something went wrong",
                            icon: "BellIcon",
                            variant: "danger",
                        },
                    });
                });
        },
    },
};
</script>
